import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";

import * as styles from "../../styles/pages/pageGrokkingTechQns.module.scss";

const DailyCodingPage = () => {
  const mdxAndSourceInfo = useStaticQuery(graphql`
    query getDailyCodingsAttempts {
      allMdx(filter: { fileAbsolutePath: { regex: "content/daily-coding/" }, frontmatter: {} }) {
        edges {
          node {
            id
            frontmatter {
              title
              description
              source
              difficulty
            }
          }
        }
      }
      allSourceInfoJson {
        edges {
          node {
            name
            icon {
              childImageSharp {
                gatsbyImageData
              }
            }
            displayName
          }
        }
      }
    }
  `);

  let allMdx = getMDX(mdxAndSourceInfo);
  const allSources = getSources(mdxAndSourceInfo);
  allMdx = updateSourceProperty(allMdx, allSources);

  const crumbs = {
    trail: [
      {
        name: "All Works",
        url: "/work",
      },
    ],
    current: {
      name: "Grokking Technical Questions",
    },
  };

  return (
    <div className={styles.content_wrapper}>
      <Breadcrumb config={crumbs} />
      <header>
        <h1>Grokking Technical Questions</h1>
        <p>
          A collection of tech questions I attempted on a daily basis. Here's where I pen down my
          solution and what I've learn from the challenge
        </p>
      </header>
      <Listing data={allMdx} />
    </div>
  );
};

export default DailyCodingPage;

const getMDX = (data) => {
  return [
    ...data.allMdx.edges.map((item) => {
      return {
        title: item.node.frontmatter.title,
        link: `grokking-technical-questions/${item.node.id}`,
        description: item.node.frontmatter.description,
        source: item.node.frontmatter.source,
        difficulty: item.node.frontmatter.difficulty,
        id: item.node.id,
      };
    }),
  ];
};

const getSources = (data) => {
  return [
    ...data.allSourceInfoJson.edges.map((item) => {
      return {
        name: item.node.name,
        icon: item.node.icon,
        displayName: item.node.displayName,
      };
    }),
  ];
};

const updateSourceProperty = (mdxData, sourceData) => {
  return [
    ...mdxData.map((mdx) => {
      const matchedSource = sourceData.filter((source) => source.name === mdx.source)[0];
      mdx.source = matchedSource;
      return mdx;
    }),
  ];
};

const Listing = ({ data }) => {
  return (
    <section>
      <ul className={styles.listing}>{data.map((list) => List(list))}</ul>
    </section>
  );
};

const difficultyConfig = {
  greatfrontend: {
    easy: {
      displayName: "Easy",
      styleColor: "#4c9832",
    },
    medium: {
      displayName: "Medium",
      styleColor: "#b38c00",
    },
    hard: {
      displayName: "Hard",
      styleColor: "#da5549",
    },
  },
  codewars: {
    "8kyu": {
      displayName: "8 Kyu",
      styleColor: "#717171",
    },
    "7kyu": {
      displayName: "7 Kyu",
      styleColor: "#717171",
    },
    "6kyu": {
      displayName: "6 Kyu",
      styleColor: "#F0B335",
    },
    "5kyu": {
      displayName: "5 Kyu",
      styleColor: "#F0B335",
    },
    "4kyu": {
      displayName: "4 Kyu",
      styleColor: "#3F77A5",
    },
    "3kyu": {
      displayName: "3 Kyu",
      styleColor: "#3F77A5",
    },
    "2kyu": {
      displayName: "2 Kyu",
      styleColor: "#836CC3",
    },
    "1kyu": {
      displayName: "1 Kyu",
      styleColor: "#836CC3",
    },
  },
};

const List = (item) => {
  const { id, title, source, difficulty, description, link } = item;
  const image = getImage(source.icon);
  const difficultyProp = difficultyConfig[source.name.toLowerCase()][difficulty.toLowerCase()];

  return (
    <li key={id}>
      <article className="card__hover">
        <div className={`${styles.card} shadow`}>
          <div className={styles.card_header}>
            <div>
              <GatsbyImage image={image} alt={source.name}></GatsbyImage>
            </div>
            <div className={styles.card_header_text}>
              <h3 className={styles.card_header_heading}>{title}</h3>
              <p
                className={styles.card_header_difficulty}
                style={{ color: difficultyProp.styleColor }}
              >
                {difficultyProp.displayName}
              </p>
            </div>
          </div>
          <p className={styles.card_desc}>{description}</p>
          <Link to={`../${link}`} className={styles.view_solution}>
            View Solution
          </Link>
        </div>
      </article>
    </li>
  );
};
